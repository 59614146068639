.footer-wrapper {
    display: flex;
    justify-content: center;
    background-color: #3F5801;
    color: #F5F8F9;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footer-container {
    width: 100%;
    height: 1rem;
    display: flex;
    padding-left: 2rem;
    display: flex;
    align-items: center;
}
