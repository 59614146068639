.recipes-wrapper {
    display: flex;
    justify-content: center;
    background-color: #F5F8F9;
    width: 100%;
}

.recipes-container {
    background: #D8E4C2;
    width: 100%;
    height: 48rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: "AoboshiOneRegular";
    font-size: 1.2rem;
    color: #3F5801;
}

.recipes-container > h2 {
    padding-left: 2rem;
}

.recipes-carousel-wrapper {
    overflow-y: auto;
    display: flex;
}

.recipes-carousel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    padding-left: 2rem;
    row-gap: 2rem;

}

@media only screen and (max-width: 600px) {
    .recipes-container > h2 {
        padding-left: 3rem;
    }
    .recipes-carousel {
        padding-left: 3rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
    }
  }

