body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #D8E4C2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  html{
    font-size: 10px;
  }
}

@font-face {
  font-family: "AoboshiOneRegular";
  src: local("AoboshiOneRegular"),
   url("./fonts/AoboshiOne/AoboshiOne-Regular.ttf") format("truetype");
  font-weight: normal;
 }

@font-face {
  font-family: "MerriweatherRegular";
  src: local("MerriweatherRegular"),
   url("./fonts/Merriweather/Merriweather-Regular.ttf") format("truetype");
  font-weight: normal;
 }
 
@font-face {
  font-family: "MerriweatherBold";
  src: local("MerriweatherBold"),
   url("./fonts/Merriweather/Merriweather-Bold.ttf") format("truetype");
  font-weight: bold;
 }
 