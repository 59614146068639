.tile {
    background-color: #F5F8F9;
    width: 15rem;
    height: 19rem;
    object-fit: cover;
    font-family: "AoboshiOneRegular";
    color: #3F5801;
    overflow: hidden;
    margin-right: 2rem;
}

.tile-image-container {
    height: 10rem;
    overflow: hidden;
    text-align: center;
    position: relative;
}

.tile-image-container > img {
    width: 15rem;
    height: 10rem;
    object-fit: cover;
}

.text-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-container > p {
    margin-top: -1rem;
    font-size: 0.8rem;
    text-align: left;
}

.tile-image-tag {
    background-color: #6E0D25;
    color: #F5F8F9;
    width: 4rem;
    text-align: right;
    display: flex;
    height: 2rem;
    position: relative;
    top: 2rem;
    margin-top: -2rem;
    padding-left: 0.5rem;
}

.tile-image-tag > p {
    margin-top: 0rem;
    position: relative;
    font-family: "AoboshiOneRegular";
}