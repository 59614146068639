/* 
    Wrapper classes are used to color the background and maintaining a max width for components.
    Components wont increase in width beyond a certain point but backgrounds will.
*/
.nav-wrapper {
    display: flex;
    justify-content: center;
    background-color: #F5F8F9;
    width: 100%;
}

.nav-container {
    background: transparent;
    width: 100%; /* change this back to 60rem if the site looks weird on massive screen sizes. Will have to change all the -container widths as well */
    height: 4rem;
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    padding-left: 2rem;
    z-index: 1;
}

.nav-logo {
    display: flex;
    align-items: center;
}

.nav-logo-image {
    width: 10rem;
}

.nav-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}
.nav-menu-container > h1 {
    font-family: "AoboshiOneRegular";
    margin-right: 1rem;
    font-size: 1.3rem;
    color: #3F5801;
}
