.ingredients-wrapper {
    display: flex;
    /* justify-content: center; */
    width: 100%;
}

.ingredients-container {
    background: #D8E4C2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 4rem;
    padding-right: 4rem;
    font-family: "AoboshiOneRegular";
    font-size: 1.2rem;
    color: #3F5801;
}
