.recipe-wrapper {
    display: flex;
    /* justify-content: center; */
    width: 100%;
}

.recipe-container {
    background: #D8E4C2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 4rem;
    padding-right: 4rem;
    font-family: "AoboshiOneRegular";
    font-size: 1.2rem;
    color: #3F5801;
}

.ingredients-box {
    padding-left: 1rem;
    padding-bottom: 1rem;
    border: 0.1rem solid;
    border-color: #3F5801;
    margin-bottom: 1rem;
}

.instructions-list > li {
    margin-top: 1rem;
}

.instructions-box {
    margin-bottom: 5rem;
}

.ingredient-link {
    font-family: "AoboshiOneRegular";
    color: #004801;
    text-decoration: none;
}

.method-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
