.poster-wrapper {
    display: flex;
    justify-content: center;
    background-color: #F5F8F9;
    width: 100%;
    overflow: hidden;
}

.poster-container {
    display: flex;
    flex-direction: row;
}

.poster-image-container {
    width: 50%;
}

.poster-image {
width: 35rem;
}

.poster-text-container {
    display: flex;
    width: 50%;
}

.poster-text {
    color: #3F5801;
    margin-top: 6rem;
    margin-left: 6rem;
    font-size: 3rem;
    inline-size: 20rem;
    overflow-wrap: break-word;
}


@media only screen and (max-width: 900px) {
    .poster-text {
        color: #3F5801;
        margin-top: 6rem;
        margin-left: 5rem;
        padding-right: 6rem;
        font-size: 2.8rem;
        inline-size: 15rem;
        overflow-wrap: break-word;
    }
    .poster-text-container {
        display: flex;
        width: 60%;
        margin-right: 1rem;
    }
    .poster-image {
        width: 30rem;
        }
        .poster-image-container {
            display: flex;
            width: 45%;
        }
  }



/* .poster-container > img{
    width: 100%;
    margin-top: -5rem;
    z-index: -1;
} */